import {Controller, useForm} from 'react-hook-form';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {Panel, TextInput} from '@sabre/spark-react-core';
import {MessageStatus} from '@sabre/spark-react-core/types';
import ActionPanel from '../../../common/components/actionPanel/ActionPanel';
import {ButtonConfig} from '../../../../../controllers/dialog/ButtonConfig';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

interface RoleFormValues {
    roleName: string;
}

const getYupValidationSchema = () => {
    return yup.object({
        roleName: yup
            .string()
            .required(tAsString('PROVIDE_A_ROLE_NAME'))
            .matches(/^[a-zA-Z0-9_]*$/, 'Alphanumeric and underscore (_) only')
            .max(100, 'Max 100 characters')
    });
}

export const CreateRole = () => {
    const controllers = useControllers();
    const roles = controllers.roles;
    const http = controllers.http;
    const dialog = controllers.dialog;

    const {control, formState: {errors}, getValues, handleSubmit} = useForm<RoleFormValues>({
        mode: 'all',
        resolver: yupResolver(getYupValidationSchema())
    });

    const submitByEnterKey = async (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        await onSubmit();
    };

    const onSubmit = async () => {
        await dialog.takeNotifiedAction({
            action: async () => {
                const createdRole = await roles.createRole(getValues('roleName'));
                roles.clearSearchedRoles();
                await roles.showParentRolesForGivenRole(createdRole.id, true);
                await http.navigateTo('/roles/search');
            },
            successMessage: tAsString('THE_X_ROLE_CREATION_SUCCESS', {
                name: getValues('roleName')
            }),
            errorMessage: tAsString('THE_X_ROLE_CREATION_FAILURE', {
                name: getValues('roleName')
            })
        });
    };

    const onClose = async () => {
        await http.navigateTo('/roles/search');
    };

    const actionButtons: ButtonConfig[] =[
        {
            className: cssClasses(
                'qa-cancel-button',
                'aat-autokey-escape'
            ),
            onClick: onClose,
            children: tAsString('CANCEL'),
            secondary: true
        },
        {
            className: 'qa-save-and-close-button',
            onClick: handleSubmit(onSubmit),
            children: tAsString('SAVE_&_CLOSE')
        }
    ];

    return (
        <>
            <h1>{tAsString('NEW_ROLE')}</h1>
            <Panel>
                <form>
                    <div className={'row'} style={{justifyContent: 'left'}}>
                        <div className={'col-lg-8'}>
                            <Controller
                                name={'roleName'}
                                control={control}
                                render={({field}) =>
                                    <TextInput
                                        name={field.name}
                                        value={field.value}
                                        onChange={field.onChange}
                                        onBlur={field.onBlur}
                                        status={errors[field.name] ? MessageStatus.ERROR : undefined}
                                        statusMessage={errors[field.name]?.message}
                                        label={tAsString('ROLE_NAME')}
                                        className={'qa-role-name-input'}
                                        maxLength={100}
                                        onKeyDown={(e) => e.key === 'Enter' && submitByEnterKey(e)}
                                    />
                                }
                            />
                        </div>
                    </div>
                </form>
            </Panel>
            <ActionPanel buttons={actionButtons}/>
        </>
    );
}