import {BehaviorSubject, Observable} from 'rxjs';
import {Controller} from '../Controller';
import {RolesSearchQuery} from '../../common/model/types/roles/RolesSearchQuery';
import {SearchType} from '../../common/model/types/assignees/SearchType';
import {ParentRole, PartialParentRole, Role} from '../../core/rest-client/aat';
import {tAsString} from '../../common/helpers/react/text/tAsString';

export class RolesController extends Controller {
    #searchedRoles = new BehaviorSubject<Array<ParentRole> | undefined>([]);
    #visibleRoles = new BehaviorSubject<Array<ParentRole> | undefined>([]);
    #nestedRoles = new BehaviorSubject<Array<Role> | undefined>([]);
    #searchedRolesToAggregate = new BehaviorSubject<Array<ParentRole> | undefined>([]);

    showLastSearchedRoles(): void {
        this.#visibleRoles.next(this.#searchedRoles.value);
        this.#nestedRoles.next([]);
    }

    clearSearchedRoles(): void {
        this.#searchedRoles.next([]);
        this.#visibleRoles.next([]);
        this.#nestedRoles.next([]);
    }

    clearSearchedRolesToAggregate(): void {
        this.#searchedRolesToAggregate.next([]);
    }

    async searchRoles(query: RolesSearchQuery, addAggregatedRolesMode = false): Promise<void> {
        addAggregatedRolesMode ? this.clearSearchedRolesToAggregate() : this.clearSearchedRoles();
        let result;

        if (query.type === SearchType.ROLE_ID)
        {
            const role = await this.getBusyFlag().run(() => this.services.roles.searchRoleById(parseInt(query.value)), 'SEARCHING_ROLES');
            result = role ? [role] : [];
        } else
        {
            result = await this.getBusyFlag().run(() => this.services.roles.searchRolesByName(query.value), 'SEARCHING_ROLES');
        }

        if (addAggregatedRolesMode)
        {
            this.#searchedRolesToAggregate.next(result.map(it => ({
                    id: it.id,
                    name: it.name,
                    enabled: true
                })));
        } else
        {
            this.#visibleRoles.next(result.map(it => ({
                id: it.id,
                name: it.name,
                enabled: true
            })));
            this.#searchedRoles.next(result.map(it => ({
                id: it.id,
                name: it.name,
                enabled: true
            })));
        }
    }

    async showParentRolesForGivenRole(roleId: number, clickedFromTable = false): Promise<Role | undefined> {
        this.#visibleRoles.next([]);
        return await this.getBusyFlag().run(async () => {
            const result = await this.services.roles.searchRoleById(roleId);
            if (result)
            {
                if (clickedFromTable)
                {
                    this.#nestedRoles.next(this.#nestedRoles.value?.filter(role => role.id !== result.id).concat(result));
                    this.#visibleRoles.next(result.parentRoles);
                } else
                {
                    this.#visibleRoles.next(result.parentRoles);
                    const index = (this.#nestedRoles.value?.findIndex(role => role.id === roleId) ?? 0) + 1;
                    this.#nestedRoles.next(this.#nestedRoles.value?.slice(0, index));
                }
            }
            return result;
        }, 'GETTING_PARENT_ROLES');
    }

    async createRole(roleName: string): Promise<Role> {
        return await this.getBusyFlag().run(async () => this.services.roles.createRole(roleName), tAsString('CREATING_ROLE'));
    }

    async updateParentRoles(id: number, parentRoles: PartialParentRole[]): Promise<Role> {
        return await this.getBusyFlag().run(async () => this.services.roles.updateParentRoles(id, parentRoles), tAsString('UPDATING_PARENT_ROLES'));
    }

    getNestedRoles$(): Observable<Array<Role> | undefined> {
        return this.#nestedRoles;
    }

    getVisibleRoles$(): Observable<Array<ParentRole> | undefined> {
        return this.#visibleRoles;
    }

    getSearchedRolesToAggregate$(): Observable<Array<ParentRole> | undefined> {
        return this.#searchedRolesToAggregate;
    }

    async getParentRoleFromDb(id: number): Promise<PartialParentRole | undefined> {
        return await this.services.roles.getRoleAsParentRole(id.toString());
    }

    async updateParentRoleInDb(role: ParentRole) {
        await this.services.roles.updateDatabase([role]);
    }
}