/*** Autogenerated. Use 'npm run generate-sources' to regenerate. ***/
import {RxJsonObject} from '../../../types/json/RxJsonObject';
import {JSONSchema4} from 'json-schema';
import {generateConverterToRx} from '../../../helpers/db/converters/generateConverterToRx';
import {generateArrayConverter} from '../../../helpers/db/converters/generateArrayConverter';
import {isDescribedByJsonSchema} from '../../../types/guards/isDescribedByJsonSchema';
import {isPartiallyDescribedByJsonSchema} from '../../../types/guards/isPartiallyDescribedByJsonSchema';
/**
 * Collection `roles` contains documents of `RxRole` type.
 */
export interface RxRole {
  /**
   * Primary key.
   */
  id: string;
  name: string;
  enabled: boolean;
}
export const RxRoleSchema: JSONSchema4 = {
    title: 'RxRole',
    primaryKey: 'id',
    required: [
        'id',
        'name',
        'enabled',
    ],
    version: 0,
    type: 'object',
    properties: {
        id: {
            maxLength: 10000,
            type: 'string',
            description: 'Primary key.',
        },
        name: {
            type: 'string',
            description: '',
        },
        enabled: {
            type: 'boolean',
            default: true,
            description: '',
        },
    },
    additionalProperties: false,
    description: 'Collection `roles` contains documents of `RxRole` type.',
}
export const asRxRole = generateConverterToRx<RxRole>(RxRoleSchema, 'RxRoleSchema');
export const asRxRoleArray = generateArrayConverter<RxRole>(asRxRole);
export function instanceOfRxRole(o?: any): o is RxRole { 
    return isDescribedByJsonSchema(o, RxRoleSchema, false, false); 
}
export function isRxRole(o?: any): o is RxRole { 
    return isDescribedByJsonSchema(o, RxRoleSchema, false, false); 
}
export function instanceOfPartialRxRole(o?: any): o is Partial<RxRole> { 
    return isPartiallyDescribedByJsonSchema(o, RxRoleSchema);
}
export function isPartialRxRole(o?: any): o is Partial<RxRole> { 
    return isPartiallyDescribedByJsonSchema(o, RxRoleSchema); 
}