/*** Autogenerated. Use 'npm run generate-sources' to regenerate. ***/
            
import {Service} from '../../services/Service';
import {Configuration, ConfigurationParameters} from './runtime';
import {ConfigurationsApi, FallbackApi, GroupsApi, MonitorApi, PCCSApi, RedAppsApi, RolesApi, VersionApi} from './apis';
import {Cached} from '../../../common/decorators/methods/Cached';
import {generateConverterToRx} from '../../../common/helpers/db/converters/generateConverterToRx';
import {generateArrayConverter} from '../../../common/helpers/db/converters/generateArrayConverter';

/* tslint:disable */
/* eslint-disable */
export * from './runtime';
export * from './apis';
export * from './models';


/**
* Service providing REST APIs for Aat
**/
export class AatApiService extends Service {
    /**
    *   Gets cached configuration object based on given configuration parameters.
    *   @param cfg Configuration parameters
    *   @returns Configuration object
    **/
    @Cached()
    private getConfiguration(cfg: ConfigurationParameters): Configuration {
        return new Configuration(cfg);
    }

                /**
                *   Promise of Configurations API client object
                *   @returns Promise of API object
                */
                get Configurations(): Promise<ConfigurationsApi> {
                    return this.services.http.getAatApiConfiguration$()
                        .then(cfg => this.getConfigurationsApi(cfg))
                }
            
                /**
                *   Gets cached Configurations API client object
                *   @param cfg API configuration
                *   @returns API client object
                */
                @Cached()
                private getConfigurationsApi(cfg: ConfigurationParameters): ConfigurationsApi {
                    return new ConfigurationsApi(this.getConfiguration(cfg));
                }
            
                /**
                *   Promise of Fallback API client object
                *   @returns Promise of API object
                */
                get Fallback(): Promise<FallbackApi> {
                    return this.services.http.getAatApiConfiguration$()
                        .then(cfg => this.getFallbackApi(cfg))
                }
            
                /**
                *   Gets cached Fallback API client object
                *   @param cfg API configuration
                *   @returns API client object
                */
                @Cached()
                private getFallbackApi(cfg: ConfigurationParameters): FallbackApi {
                    return new FallbackApi(this.getConfiguration(cfg));
                }
            
                /**
                *   Promise of Groups API client object
                *   @returns Promise of API object
                */
                get Groups(): Promise<GroupsApi> {
                    return this.services.http.getAatApiConfiguration$()
                        .then(cfg => this.getGroupsApi(cfg))
                }
            
                /**
                *   Gets cached Groups API client object
                *   @param cfg API configuration
                *   @returns API client object
                */
                @Cached()
                private getGroupsApi(cfg: ConfigurationParameters): GroupsApi {
                    return new GroupsApi(this.getConfiguration(cfg));
                }
            
                /**
                *   Promise of Monitor API client object
                *   @returns Promise of API object
                */
                get Monitor(): Promise<MonitorApi> {
                    return this.services.http.getAatApiConfiguration$()
                        .then(cfg => this.getMonitorApi(cfg))
                }
            
                /**
                *   Gets cached Monitor API client object
                *   @param cfg API configuration
                *   @returns API client object
                */
                @Cached()
                private getMonitorApi(cfg: ConfigurationParameters): MonitorApi {
                    return new MonitorApi(this.getConfiguration(cfg));
                }
            
                /**
                *   Promise of PCCS API client object
                *   @returns Promise of API object
                */
                get PCCS(): Promise<PCCSApi> {
                    return this.services.http.getAatApiConfiguration$()
                        .then(cfg => this.getPCCSApi(cfg))
                }
            
                /**
                *   Gets cached PCCS API client object
                *   @param cfg API configuration
                *   @returns API client object
                */
                @Cached()
                private getPCCSApi(cfg: ConfigurationParameters): PCCSApi {
                    return new PCCSApi(this.getConfiguration(cfg));
                }
            
                /**
                *   Promise of RedApps API client object
                *   @returns Promise of API object
                */
                get RedApps(): Promise<RedAppsApi> {
                    return this.services.http.getAatApiConfiguration$()
                        .then(cfg => this.getRedAppsApi(cfg))
                }
            
                /**
                *   Gets cached RedApps API client object
                *   @param cfg API configuration
                *   @returns API client object
                */
                @Cached()
                private getRedAppsApi(cfg: ConfigurationParameters): RedAppsApi {
                    return new RedAppsApi(this.getConfiguration(cfg));
                }
            
                /**
                *   Promise of Roles API client object
                *   @returns Promise of API object
                */
                get Roles(): Promise<RolesApi> {
                    return this.services.http.getAatApiConfiguration$()
                        .then(cfg => this.getRolesApi(cfg))
                }
            
                /**
                *   Gets cached Roles API client object
                *   @param cfg API configuration
                *   @returns API client object
                */
                @Cached()
                private getRolesApi(cfg: ConfigurationParameters): RolesApi {
                    return new RolesApi(this.getConfiguration(cfg));
                }
            
                /**
                *   Promise of Version API client object
                *   @returns Promise of API object
                */
                get Version(): Promise<VersionApi> {
                    return this.services.http.getAatApiConfiguration$()
                        .then(cfg => this.getVersionApi(cfg))
                }
            
                /**
                *   Gets cached Version API client object
                *   @param cfg API configuration
                *   @returns API client object
                */
                @Cached()
                private getVersionApi(cfg: ConfigurationParameters): VersionApi {
                    return new VersionApi(this.getConfiguration(cfg));
                }
            
}

import {AuthorizationStatus, instanceOfAuthorizationStatus, AuthorizationStatusFromJSON} from './models'; 
import {isDescribedByJsonSchema} from '../../../common/types/guards/isDescribedByJsonSchema';
import {isPartiallyDescribedByJsonSchema} from '../../../common/types/guards/isPartiallyDescribedByJsonSchema';
                
export const AuthorizationStatusSchema: any = {
    type: 'object',
    required: [
        'authStatusCode',
        'status',
    ],
    properties: {
        authDescription: {
            type: 'string',
        },
        adminType: {
            type: 'string',
        },
        authStatusCode: {
            type: 'string',
            enum: [
                'AUTHORIZED',
                'AUTHORIZED_ON_BEHALF_OF',
                'UNAUTHORIZED',
                'FORBIDDEN',
            ],
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    discriminator: {
        propertyName: 'openApiType',
    },
};       

export const asAuthorizationStatus = generateConverterToRx<AuthorizationStatus>(AuthorizationStatusSchema, 'AuthorizationStatusSchema', AuthorizationStatusFromJSON, instanceOfAuthorizationStatus, 'toCamelCase');
export const asAuthorizationStatusArray = generateArrayConverter<AuthorizationStatus>(asAuthorizationStatus);

export function isAuthorizationStatus(o?: any): o is AuthorizationStatus {
    return isDescribedByJsonSchema(o, AuthorizationStatusSchema, false, false);
}   
export function isPartialAuthorizationStatus(o?: any): o is Partial<AuthorizationStatus> {
    return isPartiallyDescribedByJsonSchema(o, AuthorizationStatusSchema);
}   
            
import {ModelConfiguration, instanceOfModelConfiguration, ModelConfigurationFromJSON} from './models'; 
                
export const ModelConfigurationSchema: any = {
    type: 'object',
    required: [
        'id',
        'redappName',
        'configurationName',
        'value',
        'description',
    ],
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        redappName: {
            type: 'string',
        },
        configurationName: {
            type: 'string',
        },
        value: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
    },
};       

export const asModelConfiguration = generateConverterToRx<ModelConfiguration>(ModelConfigurationSchema, 'ModelConfigurationSchema', ModelConfigurationFromJSON, instanceOfModelConfiguration, 'toCamelCase');
export const asModelConfigurationArray = generateArrayConverter<ModelConfiguration>(asModelConfiguration);

export function isModelConfiguration(o?: any): o is ModelConfiguration {
    return isDescribedByJsonSchema(o, ModelConfigurationSchema, false, false);
}   
export function isPartialModelConfiguration(o?: any): o is Partial<ModelConfiguration> {
    return isPartiallyDescribedByJsonSchema(o, ModelConfigurationSchema);
}   
            
import {ConfigurationResponse, instanceOfConfigurationResponse, ConfigurationResponseFromJSON} from './models'; 
                
export const ConfigurationResponseSchema: any = {
    type: 'object',
    required: [
        'configurations',
        'status',
    ],
    properties: {
        configurations: {
            type: 'array',
            items: {
                type: 'object',
                required: [
                    'id',
                    'redappName',
                    'configurationName',
                    'value',
                    'description',
                ],
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    redappName: {
                        type: 'string',
                    },
                    configurationName: {
                        type: 'string',
                    },
                    value: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                },
            },
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    discriminator: {
        propertyName: 'openApiType',
    },
};       

export const asConfigurationResponse = generateConverterToRx<ConfigurationResponse>(ConfigurationResponseSchema, 'ConfigurationResponseSchema', ConfigurationResponseFromJSON, instanceOfConfigurationResponse, 'toCamelCase');
export const asConfigurationResponseArray = generateArrayConverter<ConfigurationResponse>(asConfigurationResponse);

export function isConfigurationResponse(o?: any): o is ConfigurationResponse {
    return isDescribedByJsonSchema(o, ConfigurationResponseSchema, false, false);
}   
export function isPartialConfigurationResponse(o?: any): o is Partial<ConfigurationResponse> {
    return isPartiallyDescribedByJsonSchema(o, ConfigurationResponseSchema);
}   
            
import {CreateConfigurationRequest, instanceOfCreateConfigurationRequest, CreateConfigurationRequestFromJSON} from './models'; 
                
export const CreateConfigurationRequestSchema: any = {
    type: 'object',
    required: [
        'redappName',
        'configurationName',
        'value',
        'description',
    ],
    properties: {
        redappName: {
            type: 'string',
        },
        configurationName: {
            type: 'string',
        },
        value: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
    },
};       

export const asCreateConfigurationRequest = generateConverterToRx<CreateConfigurationRequest>(CreateConfigurationRequestSchema, 'CreateConfigurationRequestSchema', CreateConfigurationRequestFromJSON, instanceOfCreateConfigurationRequest, 'toCamelCase');
export const asCreateConfigurationRequestArray = generateArrayConverter<CreateConfigurationRequest>(asCreateConfigurationRequest);

export function isCreateConfigurationRequest(o?: any): o is CreateConfigurationRequest {
    return isDescribedByJsonSchema(o, CreateConfigurationRequestSchema, false, false);
}   
export function isPartialCreateConfigurationRequest(o?: any): o is Partial<CreateConfigurationRequest> {
    return isPartiallyDescribedByJsonSchema(o, CreateConfigurationRequestSchema);
}   
            
import {UpdateConfigurationRequest, instanceOfUpdateConfigurationRequest, UpdateConfigurationRequestFromJSON} from './models'; 
                
export const UpdateConfigurationRequestSchema: any = {
    type: 'object',
    required: [
        'configurationName',
        'value',
        'description',
    ],
    properties: {
        configurationName: {
            type: 'string',
        },
        value: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
    },
};       

export const asUpdateConfigurationRequest = generateConverterToRx<UpdateConfigurationRequest>(UpdateConfigurationRequestSchema, 'UpdateConfigurationRequestSchema', UpdateConfigurationRequestFromJSON, instanceOfUpdateConfigurationRequest, 'toCamelCase');
export const asUpdateConfigurationRequestArray = generateArrayConverter<UpdateConfigurationRequest>(asUpdateConfigurationRequest);

export function isUpdateConfigurationRequest(o?: any): o is UpdateConfigurationRequest {
    return isDescribedByJsonSchema(o, UpdateConfigurationRequestSchema, false, false);
}   
export function isPartialUpdateConfigurationRequest(o?: any): o is Partial<UpdateConfigurationRequest> {
    return isPartiallyDescribedByJsonSchema(o, UpdateConfigurationRequestSchema);
}   
            
import {Agency, instanceOfAgency, AgencyFromJSON} from './models'; 
                
export const AgencySchema: any = {
    type: 'object',
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        location: {
            type: 'object',
            properties: {
                country: {
                    type: 'string',
                },
                countryCode: {
                    type: 'string',
                },
                state: {
                    type: 'string',
                },
                city: {
                    type: 'string',
                },
                street: {
                    type: 'string',
                },
            },
            required: [
                'country',
                'countryCode',
            ],
        },
        groups: {
            type: 'array',
            items: {
                type: 'object',
                required: [
                    'id',
                    'name',
                ],
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    name: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                    totalAgents: {
                        type: 'integer',
                    },
                    editorName: {
                        type: 'string',
                    },
                },
            },
        },
    },
    required: [
        'id',
        'name',
    ],
};       

export const asAgency = generateConverterToRx<Agency>(AgencySchema, 'AgencySchema', AgencyFromJSON, instanceOfAgency, 'toCamelCase');
export const asAgencyArray = generateArrayConverter<Agency>(asAgency);

export function isAgency(o?: any): o is Agency {
    return isDescribedByJsonSchema(o, AgencySchema, false, false);
}   
export function isPartialAgency(o?: any): o is Partial<Agency> {
    return isPartiallyDescribedByJsonSchema(o, AgencySchema);
}   
            
import {Agent, instanceOfAgent, AgentFromJSON} from './models'; 
                
export const AgentSchema: any = {
    type: 'object',
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        sabreId: {
            type: 'string',
        },
        name: {
            type: 'string',
        },
        pcc: {
            type: 'object',
            properties: {
                id: {
                    type: 'integer',
                    format: 'int64',
                },
                pcc: {
                    type: 'string',
                },
                sccode: {
                    type: 'string',
                },
                agencyName: {
                    type: 'string',
                },
                location: {
                    type: 'object',
                    properties: {
                        country: {
                            type: 'string',
                        },
                        countryCode: {
                            type: 'string',
                        },
                        state: {
                            type: 'string',
                        },
                        city: {
                            type: 'string',
                        },
                        street: {
                            type: 'string',
                        },
                    },
                    required: [
                        'country',
                        'countryCode',
                    ],
                },
                agency: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'integer',
                            format: 'int64',
                        },
                        name: {
                            type: 'string',
                        },
                        location: {
                            type: 'object',
                            properties: {
                                country: {
                                    type: 'string',
                                },
                                countryCode: {
                                    type: 'string',
                                },
                                state: {
                                    type: 'string',
                                },
                                city: {
                                    type: 'string',
                                },
                                street: {
                                    type: 'string',
                                },
                            },
                            required: [
                                'country',
                                'countryCode',
                            ],
                        },
                        groups: {
                            type: 'array',
                            items: {
                                type: 'object',
                                required: [
                                    'id',
                                    'name',
                                ],
                                properties: {
                                    id: {
                                        type: 'integer',
                                        format: 'int64',
                                    },
                                    name: {
                                        type: 'string',
                                    },
                                    description: {
                                        type: 'string',
                                    },
                                    totalAgents: {
                                        type: 'integer',
                                    },
                                    editorName: {
                                        type: 'string',
                                    },
                                },
                            },
                        },
                    },
                    required: [
                        'id',
                        'name',
                    ],
                },
                groups: {
                    type: 'array',
                    items: {
                        type: 'object',
                        required: [
                            'id',
                            'name',
                        ],
                        properties: {
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            name: {
                                type: 'string',
                            },
                            description: {
                                type: 'string',
                            },
                            totalAgents: {
                                type: 'integer',
                            },
                            editorName: {
                                type: 'string',
                            },
                        },
                    },
                },
            },
            required: [
                'id',
                'pcc',
            ],
        },
        groups: {
            type: 'array',
            items: {
                type: 'object',
                required: [
                    'id',
                    'name',
                ],
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    name: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                    totalAgents: {
                        type: 'integer',
                    },
                    editorName: {
                        type: 'string',
                    },
                },
            },
        },
    },
    required: [
        'id',
        'sabreId',
        'name',
    ],
};       

export const asAgent = generateConverterToRx<Agent>(AgentSchema, 'AgentSchema', AgentFromJSON, instanceOfAgent, 'toCamelCase');
export const asAgentArray = generateArrayConverter<Agent>(asAgent);

export function isAgent(o?: any): o is Agent {
    return isDescribedByJsonSchema(o, AgentSchema, false, false);
}   
export function isPartialAgent(o?: any): o is Partial<Agent> {
    return isPartiallyDescribedByJsonSchema(o, AgentSchema);
}   
            
import {GroupsAssigneesResponse, instanceOfGroupsAssigneesResponse, GroupsAssigneesResponseFromJSON} from './models'; 
                
export const GroupsAssigneesResponseSchema: any = {
    type: 'object',
    required: [
        'agencies',
        'pccs',
        'agents',
        'status',
    ],
    properties: {
        agencies: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    name: {
                        type: 'string',
                    },
                    location: {
                        type: 'object',
                        properties: {
                            country: {
                                type: 'string',
                            },
                            countryCode: {
                                type: 'string',
                            },
                            state: {
                                type: 'string',
                            },
                            city: {
                                type: 'string',
                            },
                            street: {
                                type: 'string',
                            },
                        },
                        required: [
                            'country',
                            'countryCode',
                        ],
                    },
                    groups: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: [
                                'id',
                                'name',
                            ],
                            properties: {
                                id: {
                                    type: 'integer',
                                    format: 'int64',
                                },
                                name: {
                                    type: 'string',
                                },
                                description: {
                                    type: 'string',
                                },
                                totalAgents: {
                                    type: 'integer',
                                },
                                editorName: {
                                    type: 'string',
                                },
                            },
                        },
                    },
                },
                required: [
                    'id',
                    'name',
                ],
            },
        },
        pccs: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    pcc: {
                        type: 'string',
                    },
                    sccode: {
                        type: 'string',
                    },
                    agencyName: {
                        type: 'string',
                    },
                    location: {
                        type: 'object',
                        properties: {
                            country: {
                                type: 'string',
                            },
                            countryCode: {
                                type: 'string',
                            },
                            state: {
                                type: 'string',
                            },
                            city: {
                                type: 'string',
                            },
                            street: {
                                type: 'string',
                            },
                        },
                        required: [
                            'country',
                            'countryCode',
                        ],
                    },
                    agency: {
                        type: 'object',
                        properties: {
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            name: {
                                type: 'string',
                            },
                            location: {
                                type: 'object',
                                properties: {
                                    country: {
                                        type: 'string',
                                    },
                                    countryCode: {
                                        type: 'string',
                                    },
                                    state: {
                                        type: 'string',
                                    },
                                    city: {
                                        type: 'string',
                                    },
                                    street: {
                                        type: 'string',
                                    },
                                },
                                required: [
                                    'country',
                                    'countryCode',
                                ],
                            },
                            groups: {
                                type: 'array',
                                items: {
                                    type: 'object',
                                    required: [
                                        'id',
                                        'name',
                                    ],
                                    properties: {
                                        id: {
                                            type: 'integer',
                                            format: 'int64',
                                        },
                                        name: {
                                            type: 'string',
                                        },
                                        description: {
                                            type: 'string',
                                        },
                                        totalAgents: {
                                            type: 'integer',
                                        },
                                        editorName: {
                                            type: 'string',
                                        },
                                    },
                                },
                            },
                        },
                        required: [
                            'id',
                            'name',
                        ],
                    },
                    groups: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: [
                                'id',
                                'name',
                            ],
                            properties: {
                                id: {
                                    type: 'integer',
                                    format: 'int64',
                                },
                                name: {
                                    type: 'string',
                                },
                                description: {
                                    type: 'string',
                                },
                                totalAgents: {
                                    type: 'integer',
                                },
                                editorName: {
                                    type: 'string',
                                },
                            },
                        },
                    },
                },
                required: [
                    'id',
                    'pcc',
                ],
            },
        },
        agents: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    sabreId: {
                        type: 'string',
                    },
                    name: {
                        type: 'string',
                    },
                    pcc: {
                        type: 'object',
                        properties: {
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            pcc: {
                                type: 'string',
                            },
                            sccode: {
                                type: 'string',
                            },
                            agencyName: {
                                type: 'string',
                            },
                            location: {
                                type: 'object',
                                properties: {
                                    country: {
                                        type: 'string',
                                    },
                                    countryCode: {
                                        type: 'string',
                                    },
                                    state: {
                                        type: 'string',
                                    },
                                    city: {
                                        type: 'string',
                                    },
                                    street: {
                                        type: 'string',
                                    },
                                },
                                required: [
                                    'country',
                                    'countryCode',
                                ],
                            },
                            agency: {
                                type: 'object',
                                properties: {
                                    id: {
                                        type: 'integer',
                                        format: 'int64',
                                    },
                                    name: {
                                        type: 'string',
                                    },
                                    location: {
                                        type: 'object',
                                        properties: {
                                            country: {
                                                type: 'string',
                                            },
                                            countryCode: {
                                                type: 'string',
                                            },
                                            state: {
                                                type: 'string',
                                            },
                                            city: {
                                                type: 'string',
                                            },
                                            street: {
                                                type: 'string',
                                            },
                                        },
                                        required: [
                                            'country',
                                            'countryCode',
                                        ],
                                    },
                                    groups: {
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            required: [
                                                'id',
                                                'name',
                                            ],
                                            properties: {
                                                id: {
                                                    type: 'integer',
                                                    format: 'int64',
                                                },
                                                name: {
                                                    type: 'string',
                                                },
                                                description: {
                                                    type: 'string',
                                                },
                                                totalAgents: {
                                                    type: 'integer',
                                                },
                                                editorName: {
                                                    type: 'string',
                                                },
                                            },
                                        },
                                    },
                                },
                                required: [
                                    'id',
                                    'name',
                                ],
                            },
                            groups: {
                                type: 'array',
                                items: {
                                    type: 'object',
                                    required: [
                                        'id',
                                        'name',
                                    ],
                                    properties: {
                                        id: {
                                            type: 'integer',
                                            format: 'int64',
                                        },
                                        name: {
                                            type: 'string',
                                        },
                                        description: {
                                            type: 'string',
                                        },
                                        totalAgents: {
                                            type: 'integer',
                                        },
                                        editorName: {
                                            type: 'string',
                                        },
                                    },
                                },
                            },
                        },
                        required: [
                            'id',
                            'pcc',
                        ],
                    },
                    groups: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: [
                                'id',
                                'name',
                            ],
                            properties: {
                                id: {
                                    type: 'integer',
                                    format: 'int64',
                                },
                                name: {
                                    type: 'string',
                                },
                                description: {
                                    type: 'string',
                                },
                                totalAgents: {
                                    type: 'integer',
                                },
                                editorName: {
                                    type: 'string',
                                },
                            },
                        },
                    },
                },
                required: [
                    'id',
                    'sabreId',
                    'name',
                ],
            },
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    discriminator: {
        propertyName: 'openApiType',
    },
};       

export const asGroupsAssigneesResponse = generateConverterToRx<GroupsAssigneesResponse>(GroupsAssigneesResponseSchema, 'GroupsAssigneesResponseSchema', GroupsAssigneesResponseFromJSON, instanceOfGroupsAssigneesResponse, 'toCamelCase');
export const asGroupsAssigneesResponseArray = generateArrayConverter<GroupsAssigneesResponse>(asGroupsAssigneesResponse);

export function isGroupsAssigneesResponse(o?: any): o is GroupsAssigneesResponse {
    return isDescribedByJsonSchema(o, GroupsAssigneesResponseSchema, false, false);
}   
export function isPartialGroupsAssigneesResponse(o?: any): o is Partial<GroupsAssigneesResponse> {
    return isPartiallyDescribedByJsonSchema(o, GroupsAssigneesResponseSchema);
}   
            
import {Location, instanceOfLocation, LocationFromJSON} from './models'; 
                
export const LocationSchema: any = {
    type: 'object',
    properties: {
        country: {
            type: 'string',
        },
        countryCode: {
            type: 'string',
        },
        state: {
            type: 'string',
        },
        city: {
            type: 'string',
        },
        street: {
            type: 'string',
        },
    },
    required: [
        'country',
        'countryCode',
    ],
};       

export const asLocation = generateConverterToRx<Location>(LocationSchema, 'LocationSchema', LocationFromJSON, instanceOfLocation, 'toCamelCase');
export const asLocationArray = generateArrayConverter<Location>(asLocation);

export function isLocation(o?: any): o is Location {
    return isDescribedByJsonSchema(o, LocationSchema, false, false);
}   
export function isPartialLocation(o?: any): o is Partial<Location> {
    return isPartiallyDescribedByJsonSchema(o, LocationSchema);
}   
            
import {Pcc, instanceOfPcc, PccFromJSON} from './models'; 
                
export const PccSchema: any = {
    type: 'object',
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        pcc: {
            type: 'string',
        },
        sccode: {
            type: 'string',
        },
        agencyName: {
            type: 'string',
        },
        location: {
            type: 'object',
            properties: {
                country: {
                    type: 'string',
                },
                countryCode: {
                    type: 'string',
                },
                state: {
                    type: 'string',
                },
                city: {
                    type: 'string',
                },
                street: {
                    type: 'string',
                },
            },
            required: [
                'country',
                'countryCode',
            ],
        },
        agency: {
            type: 'object',
            properties: {
                id: {
                    type: 'integer',
                    format: 'int64',
                },
                name: {
                    type: 'string',
                },
                location: {
                    type: 'object',
                    properties: {
                        country: {
                            type: 'string',
                        },
                        countryCode: {
                            type: 'string',
                        },
                        state: {
                            type: 'string',
                        },
                        city: {
                            type: 'string',
                        },
                        street: {
                            type: 'string',
                        },
                    },
                    required: [
                        'country',
                        'countryCode',
                    ],
                },
                groups: {
                    type: 'array',
                    items: {
                        type: 'object',
                        required: [
                            'id',
                            'name',
                        ],
                        properties: {
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            name: {
                                type: 'string',
                            },
                            description: {
                                type: 'string',
                            },
                            totalAgents: {
                                type: 'integer',
                            },
                            editorName: {
                                type: 'string',
                            },
                        },
                    },
                },
            },
            required: [
                'id',
                'name',
            ],
        },
        groups: {
            type: 'array',
            items: {
                type: 'object',
                required: [
                    'id',
                    'name',
                ],
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    name: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                    totalAgents: {
                        type: 'integer',
                    },
                    editorName: {
                        type: 'string',
                    },
                },
            },
        },
    },
    required: [
        'id',
        'pcc',
    ],
};       

export const asPcc = generateConverterToRx<Pcc>(PccSchema, 'PccSchema', PccFromJSON, instanceOfPcc, 'toCamelCase');
export const asPccArray = generateArrayConverter<Pcc>(asPcc);

export function isPcc(o?: any): o is Pcc {
    return isDescribedByJsonSchema(o, PccSchema, false, false);
}   
export function isPartialPcc(o?: any): o is Partial<Pcc> {
    return isPartiallyDescribedByJsonSchema(o, PccSchema);
}   
            
import {Group, instanceOfGroup, GroupFromJSON} from './models'; 
                
export const GroupSchema: any = {
    type: 'object',
    required: [
        'id',
        'name',
    ],
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
        totalAgents: {
            type: 'integer',
        },
        editorName: {
            type: 'string',
        },
    },
};       

export const asGroup = generateConverterToRx<Group>(GroupSchema, 'GroupSchema', GroupFromJSON, instanceOfGroup, 'toCamelCase');
export const asGroupArray = generateArrayConverter<Group>(asGroup);

export function isGroup(o?: any): o is Group {
    return isDescribedByJsonSchema(o, GroupSchema, false, false);
}   
export function isPartialGroup(o?: any): o is Partial<Group> {
    return isPartiallyDescribedByJsonSchema(o, GroupSchema);
}   
            
import {GroupConfigurationRequest, instanceOfGroupConfigurationRequest, GroupConfigurationRequestFromJSON} from './models'; 
                
export const GroupConfigurationRequestSchema: any = {
    type: 'object',
    properties: {
        cmGroupIds: {
            type: 'array',
            items: {
                type: 'integer',
                format: 'int64',
            },
        },
        groupId: {
            type: 'integer',
            format: 'int64',
        },
    },
    required: [
        'cmGroupIds',
    ],
};       

export const asGroupConfigurationRequest = generateConverterToRx<GroupConfigurationRequest>(GroupConfigurationRequestSchema, 'GroupConfigurationRequestSchema', GroupConfigurationRequestFromJSON, instanceOfGroupConfigurationRequest, 'toCamelCase');
export const asGroupConfigurationRequestArray = generateArrayConverter<GroupConfigurationRequest>(asGroupConfigurationRequest);

export function isGroupConfigurationRequest(o?: any): o is GroupConfigurationRequest {
    return isDescribedByJsonSchema(o, GroupConfigurationRequestSchema, false, false);
}   
export function isPartialGroupConfigurationRequest(o?: any): o is Partial<GroupConfigurationRequest> {
    return isPartiallyDescribedByJsonSchema(o, GroupConfigurationRequestSchema);
}   
            
import {GroupRequest, instanceOfGroupRequest, GroupRequestFromJSON} from './models'; 
                
export const GroupRequestSchema: any = {
    type: 'object',
    properties: {
        group: {
            type: 'object',
            properties: {
                id: {
                    type: 'integer',
                    format: 'int64',
                },
                name: {
                    type: 'string',
                },
                description: {
                    type: 'string',
                },
                totalAgents: {
                    type: 'integer',
                },
                editorName: {
                    type: 'string',
                },
            },
        },
        workspace: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    productId: {
                        type: 'integer',
                        format: 'int64',
                    },
                    productEnablement: {
                        type: 'string',
                        enum: [
                            'ON',
                            'OFF',
                            'DEFAULT',
                        ],
                    },
                },
                required: [
                    'id',
                    'productEnablement',
                ],
            },
        },
        redapps: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    productId: {
                        type: 'integer',
                        format: 'int64',
                    },
                    redAppPlacement: {
                        type: 'string',
                        enum: [
                            'apps-panel',
                            'sidebar',
                        ],
                    },
                    redAppConfiguration: {
                        type: 'string',
                    },
                },
                required: [
                    'productId',
                    'redAppPlacement',
                    'redAppConfiguration',
                ],
            },
        },
        properties: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    propertyId: {
                        type: 'integer',
                        format: 'int64',
                    },
                    overrideValue: {
                        type: 'string',
                    },
                    allowUserOverride: {
                        type: 'boolean',
                    },
                },
                required: [
                    'propertyId',
                    'allowUserOverride',
                ],
            },
        },
        cmGroupIds: {
            type: 'array',
            items: {
                type: 'integer',
                format: 'int64',
            },
        },
    },
    required: [
        'group',
    ],
};       

export const asGroupRequest = generateConverterToRx<GroupRequest>(GroupRequestSchema, 'GroupRequestSchema', GroupRequestFromJSON, instanceOfGroupRequest, 'toCamelCase');
export const asGroupRequestArray = generateArrayConverter<GroupRequest>(asGroupRequest);

export function isGroupRequest(o?: any): o is GroupRequest {
    return isDescribedByJsonSchema(o, GroupRequestSchema, false, false);
}   
export function isPartialGroupRequest(o?: any): o is Partial<GroupRequest> {
    return isPartiallyDescribedByJsonSchema(o, GroupRequestSchema);
}   
            
import {GroupResponse, instanceOfGroupResponse, GroupResponseFromJSON} from './models'; 
                
export const GroupResponseSchema: any = {
    type: 'object',
    required: [
        'group',
        'status',
    ],
    properties: {
        group: {
            type: 'object',
            required: [
                'id',
                'name',
            ],
            properties: {
                id: {
                    type: 'integer',
                    format: 'int64',
                },
                name: {
                    type: 'string',
                },
                description: {
                    type: 'string',
                },
                totalAgents: {
                    type: 'integer',
                },
                editorName: {
                    type: 'string',
                },
            },
        },
        workspace: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    productId: {
                        type: 'integer',
                        format: 'int64',
                    },
                    productEnablement: {
                        type: 'string',
                        enum: [
                            'ON',
                            'OFF',
                            'DEFAULT',
                        ],
                    },
                },
                required: [
                    'id',
                    'productEnablement',
                ],
            },
        },
        properties: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    propertyId: {
                        type: 'integer',
                        format: 'int64',
                    },
                    overrideValue: {
                        type: 'string',
                    },
                    allowUserOverride: {
                        type: 'boolean',
                    },
                },
                required: [
                    'propertyId',
                    'allowUserOverride',
                ],
            },
        },
        redapps: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    productId: {
                        type: 'integer',
                        format: 'int64',
                    },
                    redAppPlacement: {
                        type: 'string',
                        enum: [
                            'apps-panel',
                            'sidebar',
                        ],
                    },
                    redAppConfiguration: {
                        type: 'string',
                    },
                },
                required: [
                    'productId',
                    'redAppPlacement',
                    'redAppConfiguration',
                ],
            },
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    discriminator: {
        propertyName: 'openApiType',
    },
};       

export const asGroupResponse = generateConverterToRx<GroupResponse>(GroupResponseSchema, 'GroupResponseSchema', GroupResponseFromJSON, instanceOfGroupResponse, 'toCamelCase');
export const asGroupResponseArray = generateArrayConverter<GroupResponse>(asGroupResponse);

export function isGroupResponse(o?: any): o is GroupResponse {
    return isDescribedByJsonSchema(o, GroupResponseSchema, false, false);
}   
export function isPartialGroupResponse(o?: any): o is Partial<GroupResponse> {
    return isPartiallyDescribedByJsonSchema(o, GroupResponseSchema);
}   
            
import {GroupsResponse, instanceOfGroupsResponse, GroupsResponseFromJSON} from './models'; 
                
export const GroupsResponseSchema: any = {
    type: 'object',
    required: [
        'groups',
        'status',
    ],
    properties: {
        groups: {
            type: 'array',
            items: {
                type: 'object',
                required: [
                    'id',
                    'name',
                ],
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    name: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                    totalAgents: {
                        type: 'integer',
                    },
                    editorName: {
                        type: 'string',
                    },
                },
            },
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    discriminator: {
        propertyName: 'openApiType',
    },
};       

export const asGroupsResponse = generateConverterToRx<GroupsResponse>(GroupsResponseSchema, 'GroupsResponseSchema', GroupsResponseFromJSON, instanceOfGroupsResponse, 'toCamelCase');
export const asGroupsResponseArray = generateArrayConverter<GroupsResponse>(asGroupsResponse);

export function isGroupsResponse(o?: any): o is GroupsResponse {
    return isDescribedByJsonSchema(o, GroupsResponseSchema, false, false);
}   
export function isPartialGroupsResponse(o?: any): o is Partial<GroupsResponse> {
    return isPartiallyDescribedByJsonSchema(o, GroupsResponseSchema);
}   
            
import {PartialGroup, instanceOfPartialGroup, PartialGroupFromJSON} from './models'; 
                
export const PartialGroupSchema: any = {
    type: 'object',
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
        totalAgents: {
            type: 'integer',
        },
        editorName: {
            type: 'string',
        },
    },
};       

export const asPartialGroup = generateConverterToRx<PartialGroup>(PartialGroupSchema, 'PartialGroupSchema', PartialGroupFromJSON, instanceOfPartialGroup, 'toCamelCase');
export const asPartialGroupArray = generateArrayConverter<PartialGroup>(asPartialGroup);

export function isInstanceOfPartialGroup(o?: any): o is PartialGroup {
    return isDescribedByJsonSchema(o, PartialGroupSchema, false, false);
}   
export function isPartialInstanceOfPartialGroup(o?: any): o is Partial<PartialGroup> {
    return isPartiallyDescribedByJsonSchema(o, PartialGroupSchema);
}   
            
import {GroupParticipantsRequest, instanceOfGroupParticipantsRequest, GroupParticipantsRequestFromJSON} from './models'; 
                
export const GroupParticipantsRequestSchema: any = {
    type: 'object',
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        searchCriteria: {
            type: 'object',
            properties: {
                pattern: {
                    type: 'string',
                },
                type: {
                    type: 'string',
                    enum: [
                        'PCC',
                        'COUNTRY',
                        'AGENCY_NAME',
                        'SABRE_ID',
                        'AGENT_NAME',
                    ],
                },
            },
        },
    },
};       

export const asGroupParticipantsRequest = generateConverterToRx<GroupParticipantsRequest>(GroupParticipantsRequestSchema, 'GroupParticipantsRequestSchema', GroupParticipantsRequestFromJSON, instanceOfGroupParticipantsRequest, 'toCamelCase');
export const asGroupParticipantsRequestArray = generateArrayConverter<GroupParticipantsRequest>(asGroupParticipantsRequest);

export function isGroupParticipantsRequest(o?: any): o is GroupParticipantsRequest {
    return isDescribedByJsonSchema(o, GroupParticipantsRequestSchema, false, false);
}   
export function isPartialGroupParticipantsRequest(o?: any): o is Partial<GroupParticipantsRequest> {
    return isPartiallyDescribedByJsonSchema(o, GroupParticipantsRequestSchema);
}   
            
import {GroupParticipantsResponse, instanceOfGroupParticipantsResponse, GroupParticipantsResponseFromJSON} from './models'; 
                
export const GroupParticipantsResponseSchema: any = {
    type: 'object',
    properties: {
        i18nLvl: {
            type: 'object',
            properties: {
                id: {
                    type: 'integer',
                    format: 'int64',
                },
                name: {
                    type: 'string',
                },
                location: {
                    type: 'object',
                    properties: {
                        country: {
                            type: 'string',
                        },
                        countryCode: {
                            type: 'string',
                        },
                        state: {
                            type: 'string',
                        },
                        city: {
                            type: 'string',
                        },
                        street: {
                            type: 'string',
                        },
                    },
                    required: [
                        'country',
                        'countryCode',
                    ],
                },
                groups: {
                    type: 'array',
                    items: {
                        type: 'object',
                        required: [
                            'id',
                            'name',
                        ],
                        properties: {
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            name: {
                                type: 'string',
                            },
                            description: {
                                type: 'string',
                            },
                            totalAgents: {
                                type: 'integer',
                            },
                            editorName: {
                                type: 'string',
                            },
                        },
                    },
                },
            },
            required: [
                'id',
                'name',
            ],
        },
        cntrLvl: {
            type: 'object',
            properties: {
                id: {
                    type: 'integer',
                    format: 'int64',
                },
                name: {
                    type: 'string',
                },
                location: {
                    type: 'object',
                    properties: {
                        country: {
                            type: 'string',
                        },
                        countryCode: {
                            type: 'string',
                        },
                        state: {
                            type: 'string',
                        },
                        city: {
                            type: 'string',
                        },
                        street: {
                            type: 'string',
                        },
                    },
                    required: [
                        'country',
                        'countryCode',
                    ],
                },
                groups: {
                    type: 'array',
                    items: {
                        type: 'object',
                        required: [
                            'id',
                            'name',
                        ],
                        properties: {
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            name: {
                                type: 'string',
                            },
                            description: {
                                type: 'string',
                            },
                            totalAgents: {
                                type: 'integer',
                            },
                            editorName: {
                                type: 'string',
                            },
                        },
                    },
                },
            },
            required: [
                'id',
                'name',
            ],
        },
        pccLvl: {
            type: 'object',
            properties: {
                id: {
                    type: 'integer',
                    format: 'int64',
                },
                pcc: {
                    type: 'string',
                },
                sccode: {
                    type: 'string',
                },
                agencyName: {
                    type: 'string',
                },
                location: {
                    type: 'object',
                    properties: {
                        country: {
                            type: 'string',
                        },
                        countryCode: {
                            type: 'string',
                        },
                        state: {
                            type: 'string',
                        },
                        city: {
                            type: 'string',
                        },
                        street: {
                            type: 'string',
                        },
                    },
                    required: [
                        'country',
                        'countryCode',
                    ],
                },
                agency: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'integer',
                            format: 'int64',
                        },
                        name: {
                            type: 'string',
                        },
                        location: {
                            type: 'object',
                            properties: {
                                country: {
                                    type: 'string',
                                },
                                countryCode: {
                                    type: 'string',
                                },
                                state: {
                                    type: 'string',
                                },
                                city: {
                                    type: 'string',
                                },
                                street: {
                                    type: 'string',
                                },
                            },
                            required: [
                                'country',
                                'countryCode',
                            ],
                        },
                        groups: {
                            type: 'array',
                            items: {
                                type: 'object',
                                required: [
                                    'id',
                                    'name',
                                ],
                                properties: {
                                    id: {
                                        type: 'integer',
                                        format: 'int64',
                                    },
                                    name: {
                                        type: 'string',
                                    },
                                    description: {
                                        type: 'string',
                                    },
                                    totalAgents: {
                                        type: 'integer',
                                    },
                                    editorName: {
                                        type: 'string',
                                    },
                                },
                            },
                        },
                    },
                    required: [
                        'id',
                        'name',
                    ],
                },
                groups: {
                    type: 'array',
                    items: {
                        type: 'object',
                        required: [
                            'id',
                            'name',
                        ],
                        properties: {
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            name: {
                                type: 'string',
                            },
                            description: {
                                type: 'string',
                            },
                            totalAgents: {
                                type: 'integer',
                            },
                            editorName: {
                                type: 'string',
                            },
                        },
                    },
                },
            },
            required: [
                'id',
                'pcc',
            ],
        },
        cntrNo: {
            type: 'integer',
            format: 'int64',
        },
        pccNo: {
            type: 'integer',
            format: 'int64',
        },
        cntrLst: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    name: {
                        type: 'string',
                    },
                    location: {
                        type: 'object',
                        properties: {
                            country: {
                                type: 'string',
                            },
                            countryCode: {
                                type: 'string',
                            },
                            state: {
                                type: 'string',
                            },
                            city: {
                                type: 'string',
                            },
                            street: {
                                type: 'string',
                            },
                        },
                        required: [
                            'country',
                            'countryCode',
                        ],
                    },
                    groups: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: [
                                'id',
                                'name',
                            ],
                            properties: {
                                id: {
                                    type: 'integer',
                                    format: 'int64',
                                },
                                name: {
                                    type: 'string',
                                },
                                description: {
                                    type: 'string',
                                },
                                totalAgents: {
                                    type: 'integer',
                                },
                                editorName: {
                                    type: 'string',
                                },
                            },
                        },
                    },
                },
                required: [
                    'id',
                    'name',
                ],
            },
        },
        pccLst: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    pcc: {
                        type: 'string',
                    },
                    sccode: {
                        type: 'string',
                    },
                    agencyName: {
                        type: 'string',
                    },
                    location: {
                        type: 'object',
                        properties: {
                            country: {
                                type: 'string',
                            },
                            countryCode: {
                                type: 'string',
                            },
                            state: {
                                type: 'string',
                            },
                            city: {
                                type: 'string',
                            },
                            street: {
                                type: 'string',
                            },
                        },
                        required: [
                            'country',
                            'countryCode',
                        ],
                    },
                    agency: {
                        type: 'object',
                        properties: {
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            name: {
                                type: 'string',
                            },
                            location: {
                                type: 'object',
                                properties: {
                                    country: {
                                        type: 'string',
                                    },
                                    countryCode: {
                                        type: 'string',
                                    },
                                    state: {
                                        type: 'string',
                                    },
                                    city: {
                                        type: 'string',
                                    },
                                    street: {
                                        type: 'string',
                                    },
                                },
                                required: [
                                    'country',
                                    'countryCode',
                                ],
                            },
                            groups: {
                                type: 'array',
                                items: {
                                    type: 'object',
                                    required: [
                                        'id',
                                        'name',
                                    ],
                                    properties: {
                                        id: {
                                            type: 'integer',
                                            format: 'int64',
                                        },
                                        name: {
                                            type: 'string',
                                        },
                                        description: {
                                            type: 'string',
                                        },
                                        totalAgents: {
                                            type: 'integer',
                                        },
                                        editorName: {
                                            type: 'string',
                                        },
                                    },
                                },
                            },
                        },
                        required: [
                            'id',
                            'name',
                        ],
                    },
                    groups: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: [
                                'id',
                                'name',
                            ],
                            properties: {
                                id: {
                                    type: 'integer',
                                    format: 'int64',
                                },
                                name: {
                                    type: 'string',
                                },
                                description: {
                                    type: 'string',
                                },
                                totalAgents: {
                                    type: 'integer',
                                },
                                editorName: {
                                    type: 'string',
                                },
                            },
                        },
                    },
                },
                required: [
                    'id',
                    'pcc',
                ],
            },
        },
        agentLst: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    sabreId: {
                        type: 'string',
                    },
                    name: {
                        type: 'string',
                    },
                    pcc: {
                        type: 'object',
                        properties: {
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            pcc: {
                                type: 'string',
                            },
                            sccode: {
                                type: 'string',
                            },
                            agencyName: {
                                type: 'string',
                            },
                            location: {
                                type: 'object',
                                properties: {
                                    country: {
                                        type: 'string',
                                    },
                                    countryCode: {
                                        type: 'string',
                                    },
                                    state: {
                                        type: 'string',
                                    },
                                    city: {
                                        type: 'string',
                                    },
                                    street: {
                                        type: 'string',
                                    },
                                },
                                required: [
                                    'country',
                                    'countryCode',
                                ],
                            },
                            agency: {
                                type: 'object',
                                properties: {
                                    id: {
                                        type: 'integer',
                                        format: 'int64',
                                    },
                                    name: {
                                        type: 'string',
                                    },
                                    location: {
                                        type: 'object',
                                        properties: {
                                            country: {
                                                type: 'string',
                                            },
                                            countryCode: {
                                                type: 'string',
                                            },
                                            state: {
                                                type: 'string',
                                            },
                                            city: {
                                                type: 'string',
                                            },
                                            street: {
                                                type: 'string',
                                            },
                                        },
                                        required: [
                                            'country',
                                            'countryCode',
                                        ],
                                    },
                                    groups: {
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            required: [
                                                'id',
                                                'name',
                                            ],
                                            properties: {
                                                id: {
                                                    type: 'integer',
                                                    format: 'int64',
                                                },
                                                name: {
                                                    type: 'string',
                                                },
                                                description: {
                                                    type: 'string',
                                                },
                                                totalAgents: {
                                                    type: 'integer',
                                                },
                                                editorName: {
                                                    type: 'string',
                                                },
                                            },
                                        },
                                    },
                                },
                                required: [
                                    'id',
                                    'name',
                                ],
                            },
                            groups: {
                                type: 'array',
                                items: {
                                    type: 'object',
                                    required: [
                                        'id',
                                        'name',
                                    ],
                                    properties: {
                                        id: {
                                            type: 'integer',
                                            format: 'int64',
                                        },
                                        name: {
                                            type: 'string',
                                        },
                                        description: {
                                            type: 'string',
                                        },
                                        totalAgents: {
                                            type: 'integer',
                                        },
                                        editorName: {
                                            type: 'string',
                                        },
                                    },
                                },
                            },
                        },
                        required: [
                            'id',
                            'pcc',
                        ],
                    },
                    groups: {
                        type: 'array',
                        items: {
                            type: 'object',
                            required: [
                                'id',
                                'name',
                            ],
                            properties: {
                                id: {
                                    type: 'integer',
                                    format: 'int64',
                                },
                                name: {
                                    type: 'string',
                                },
                                description: {
                                    type: 'string',
                                },
                                totalAgents: {
                                    type: 'integer',
                                },
                                editorName: {
                                    type: 'string',
                                },
                            },
                        },
                    },
                },
                required: [
                    'id',
                    'sabreId',
                    'name',
                ],
            },
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    discriminator: {
        propertyName: 'openApiType',
    },
    required: [
        'status',
    ],
};       

export const asGroupParticipantsResponse = generateConverterToRx<GroupParticipantsResponse>(GroupParticipantsResponseSchema, 'GroupParticipantsResponseSchema', GroupParticipantsResponseFromJSON, instanceOfGroupParticipantsResponse, 'toCamelCase');
export const asGroupParticipantsResponseArray = generateArrayConverter<GroupParticipantsResponse>(asGroupParticipantsResponse);

export function isGroupParticipantsResponse(o?: any): o is GroupParticipantsResponse {
    return isDescribedByJsonSchema(o, GroupParticipantsResponseSchema, false, false);
}   
export function isPartialGroupParticipantsResponse(o?: any): o is Partial<GroupParticipantsResponse> {
    return isPartiallyDescribedByJsonSchema(o, GroupParticipantsResponseSchema);
}   
            
import {GroupParticipantsSearchCriteria, instanceOfGroupParticipantsSearchCriteria, GroupParticipantsSearchCriteriaFromJSON} from './models'; 
                
export const GroupParticipantsSearchCriteriaSchema: any = {
    type: 'object',
    properties: {
        pattern: {
            type: 'string',
        },
        type: {
            type: 'string',
            enum: [
                'PCC',
                'COUNTRY',
                'AGENCY_NAME',
                'SABRE_ID',
                'AGENT_NAME',
            ],
        },
    },
};       

export const asGroupParticipantsSearchCriteria = generateConverterToRx<GroupParticipantsSearchCriteria>(GroupParticipantsSearchCriteriaSchema, 'GroupParticipantsSearchCriteriaSchema', GroupParticipantsSearchCriteriaFromJSON, instanceOfGroupParticipantsSearchCriteria, 'toCamelCase');
export const asGroupParticipantsSearchCriteriaArray = generateArrayConverter<GroupParticipantsSearchCriteria>(asGroupParticipantsSearchCriteria);

export function isGroupParticipantsSearchCriteria(o?: any): o is GroupParticipantsSearchCriteria {
    return isDescribedByJsonSchema(o, GroupParticipantsSearchCriteriaSchema, false, false);
}   
export function isPartialGroupParticipantsSearchCriteria(o?: any): o is Partial<GroupParticipantsSearchCriteria> {
    return isPartiallyDescribedByJsonSchema(o, GroupParticipantsSearchCriteriaSchema);
}   
            
import {FixedSettings, instanceOfFixedSettings, FixedSettingsFromJSON} from './models'; 
                
export const FixedSettingsSchema: any = {
    type: 'object',
    properties: {
        name: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
    },
    required: [
        'name',
    ],
};       

export const asFixedSettings = generateConverterToRx<FixedSettings>(FixedSettingsSchema, 'FixedSettingsSchema', FixedSettingsFromJSON, instanceOfFixedSettings, 'toCamelCase');
export const asFixedSettingsArray = generateArrayConverter<FixedSettings>(asFixedSettings);

export function isFixedSettings(o?: any): o is FixedSettings {
    return isDescribedByJsonSchema(o, FixedSettingsSchema, false, false);
}   
export function isPartialFixedSettings(o?: any): o is Partial<FixedSettings> {
    return isPartiallyDescribedByJsonSchema(o, FixedSettingsSchema);
}   
            
import {GroupInfo, instanceOfGroupInfo, GroupInfoFromJSON} from './models'; 
                
export const GroupInfoSchema: any = {
    type: 'object',
    properties: {
        usingAgentsDetails: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    assignee: {
                        type: 'string',
                    },
                    agent: {
                        type: 'string',
                    },
                    level: {
                        type: 'string',
                        enum: [
                            'USER',
                            'PCC',
                            'AGENCY',
                        ],
                    },
                },
                required: [
                    'id',
                    'assignee',
                    'agent',
                    'level',
                ],
            },
        },
    },
};       

export const asGroupInfo = generateConverterToRx<GroupInfo>(GroupInfoSchema, 'GroupInfoSchema', GroupInfoFromJSON, instanceOfGroupInfo, 'toCamelCase');
export const asGroupInfoArray = generateArrayConverter<GroupInfo>(asGroupInfo);

export function isGroupInfo(o?: any): o is GroupInfo {
    return isDescribedByJsonSchema(o, GroupInfoSchema, false, false);
}   
export function isPartialGroupInfo(o?: any): o is Partial<GroupInfo> {
    return isPartiallyDescribedByJsonSchema(o, GroupInfoSchema);
}   
            
import {GroupSettingsIdsReponse, instanceOfGroupSettingsIdsReponse, GroupSettingsIdsReponseFromJSON} from './models'; 
                
export const GroupSettingsIdsReponseSchema: any = {
    type: 'object',
    properties: {
        workspace: {
            type: 'object',
            properties: {
                usingAgentsDetails: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties: {
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            assignee: {
                                type: 'string',
                            },
                            agent: {
                                type: 'string',
                            },
                            level: {
                                type: 'string',
                                enum: [
                                    'USER',
                                    'PCC',
                                    'AGENCY',
                                ],
                            },
                        },
                        required: [
                            'id',
                            'assignee',
                            'agent',
                            'level',
                        ],
                    },
                },
            },
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    discriminator: {
        propertyName: 'openApiType',
    },
    required: [
        'status',
    ],
};       

export const asGroupSettingsIdsReponse = generateConverterToRx<GroupSettingsIdsReponse>(GroupSettingsIdsReponseSchema, 'GroupSettingsIdsReponseSchema', GroupSettingsIdsReponseFromJSON, instanceOfGroupSettingsIdsReponse, 'toCamelCase');
export const asGroupSettingsIdsReponseArray = generateArrayConverter<GroupSettingsIdsReponse>(asGroupSettingsIdsReponse);

export function isGroupSettingsIdsReponse(o?: any): o is GroupSettingsIdsReponse {
    return isDescribedByJsonSchema(o, GroupSettingsIdsReponseSchema, false, false);
}   
export function isPartialGroupSettingsIdsReponse(o?: any): o is Partial<GroupSettingsIdsReponse> {
    return isPartiallyDescribedByJsonSchema(o, GroupSettingsIdsReponseSchema);
}   
            
import {GroupSettingsResponse, instanceOfGroupSettingsResponse, GroupSettingsResponseFromJSON} from './models'; 
                
export const GroupSettingsResponseSchema: any = {
    type: 'object',
    properties: {
        fixedSettings: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                },
                required: [
                    'name',
                ],
            },
        },
        workspace: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    name: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                    totalAgents: {
                        type: 'integer',
                    },
                    usingAgents: {
                        type: 'integer',
                    },
                    productEnablement: {
                        type: 'string',
                        enum: [
                            'ON',
                            'OFF',
                            'DEFAULT',
                        ],
                    },
                    redappPlacement: {
                        type: 'string',
                        enum: [
                            'apps-panel',
                            'sidebar',
                        ],
                    },
                    redappConfiguration: {
                        type: 'string',
                    },
                },
                required: [
                    'id',
                    'name',
                ],
            },
        },
        redApps: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    name: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                    totalAgents: {
                        type: 'integer',
                    },
                    usingAgents: {
                        type: 'integer',
                    },
                    productEnablement: {
                        type: 'string',
                        enum: [
                            'ON',
                            'OFF',
                            'DEFAULT',
                        ],
                    },
                    redappPlacement: {
                        type: 'string',
                        enum: [
                            'apps-panel',
                            'sidebar',
                        ],
                    },
                    redappConfiguration: {
                        type: 'string',
                    },
                },
                required: [
                    'id',
                    'name',
                ],
            },
        },
        properties: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    name: {
                        type: 'string',
                    },
                    description: {
                        type: 'string',
                    },
                    overrideValue: {
                        type: 'string',
                    },
                    possibleValues: {
                        type: 'object',
                        additionalProperties: {
                            type: 'string',
                        },
                    },
                    allowUserOverride: {
                        type: 'boolean',
                    },
                },
                required: [
                    'id',
                ],
            },
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    discriminator: {
        propertyName: 'openApiType',
    },
    required: [
        'status',
    ],
};       

export const asGroupSettingsResponse = generateConverterToRx<GroupSettingsResponse>(GroupSettingsResponseSchema, 'GroupSettingsResponseSchema', GroupSettingsResponseFromJSON, instanceOfGroupSettingsResponse, 'toCamelCase');
export const asGroupSettingsResponseArray = generateArrayConverter<GroupSettingsResponse>(asGroupSettingsResponse);

export function isGroupSettingsResponse(o?: any): o is GroupSettingsResponse {
    return isDescribedByJsonSchema(o, GroupSettingsResponseSchema, false, false);
}   
export function isPartialGroupSettingsResponse(o?: any): o is Partial<GroupSettingsResponse> {
    return isPartiallyDescribedByJsonSchema(o, GroupSettingsResponseSchema);
}   
            
import {Property, instanceOfProperty, PropertyFromJSON} from './models'; 
                
export const PropertySchema: any = {
    type: 'object',
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
        overrideValue: {
            type: 'string',
        },
        possibleValues: {
            type: 'object',
            additionalProperties: {
                type: 'string',
            },
        },
        allowUserOverride: {
            type: 'boolean',
        },
    },
    required: [
        'id',
    ],
};       

export const asProperty = generateConverterToRx<Property>(PropertySchema, 'PropertySchema', PropertyFromJSON, instanceOfProperty, 'toCamelCase');
export const asPropertyArray = generateArrayConverter<Property>(asProperty);

export function isProperty(o?: any): o is Property {
    return isDescribedByJsonSchema(o, PropertySchema, false, false);
}   
export function isPartialProperty(o?: any): o is Partial<Property> {
    return isPartiallyDescribedByJsonSchema(o, PropertySchema);
}   
            
import {PropertyUpdate, instanceOfPropertyUpdate, PropertyUpdateFromJSON} from './models'; 
                
export const PropertyUpdateSchema: any = {
    type: 'object',
    properties: {
        propertyId: {
            type: 'integer',
            format: 'int64',
        },
        overrideValue: {
            type: 'string',
        },
        allowUserOverride: {
            type: 'boolean',
        },
    },
    required: [
        'propertyId',
        'allowUserOverride',
    ],
};       

export const asPropertyUpdate = generateConverterToRx<PropertyUpdate>(PropertyUpdateSchema, 'PropertyUpdateSchema', PropertyUpdateFromJSON, instanceOfPropertyUpdate, 'toCamelCase');
export const asPropertyUpdateArray = generateArrayConverter<PropertyUpdate>(asPropertyUpdate);

export function isPropertyUpdate(o?: any): o is PropertyUpdate {
    return isDescribedByJsonSchema(o, PropertyUpdateSchema, false, false);
}   
export function isPartialPropertyUpdate(o?: any): o is Partial<PropertyUpdate> {
    return isPartiallyDescribedByJsonSchema(o, PropertyUpdateSchema);
}   
            
import {RedAppPlacementSetting, instanceOfRedAppPlacementSetting, RedAppPlacementSettingFromJSON} from './models'; 
                
export const RedAppPlacementSettingSchema: any = {
    type: 'object',
    properties: {
        productId: {
            type: 'integer',
            format: 'int64',
        },
        redAppPlacement: {
            type: 'string',
            enum: [
                'apps-panel',
                'sidebar',
            ],
        },
        redAppConfiguration: {
            type: 'string',
        },
    },
    required: [
        'productId',
        'redAppPlacement',
        'redAppConfiguration',
    ],
};       

export const asRedAppPlacementSetting = generateConverterToRx<RedAppPlacementSetting>(RedAppPlacementSettingSchema, 'RedAppPlacementSettingSchema', RedAppPlacementSettingFromJSON, instanceOfRedAppPlacementSetting, 'toCamelCase');
export const asRedAppPlacementSettingArray = generateArrayConverter<RedAppPlacementSetting>(asRedAppPlacementSetting);

export function isRedAppPlacementSetting(o?: any): o is RedAppPlacementSetting {
    return isDescribedByJsonSchema(o, RedAppPlacementSettingSchema, false, false);
}   
export function isPartialRedAppPlacementSetting(o?: any): o is Partial<RedAppPlacementSetting> {
    return isPartiallyDescribedByJsonSchema(o, RedAppPlacementSettingSchema);
}   
            
import {Workspace, instanceOfWorkspace, WorkspaceFromJSON} from './models'; 
                
export const WorkspaceSchema: any = {
    type: 'object',
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
        description: {
            type: 'string',
        },
        totalAgents: {
            type: 'integer',
        },
        usingAgents: {
            type: 'integer',
        },
        productEnablement: {
            type: 'string',
            enum: [
                'ON',
                'OFF',
                'DEFAULT',
            ],
        },
        redappPlacement: {
            type: 'string',
            enum: [
                'apps-panel',
                'sidebar',
            ],
        },
        redappConfiguration: {
            type: 'string',
        },
    },
    required: [
        'id',
        'name',
    ],
};       

export const asWorkspace = generateConverterToRx<Workspace>(WorkspaceSchema, 'WorkspaceSchema', WorkspaceFromJSON, instanceOfWorkspace, 'toCamelCase');
export const asWorkspaceArray = generateArrayConverter<Workspace>(asWorkspace);

export function isWorkspace(o?: any): o is Workspace {
    return isDescribedByJsonSchema(o, WorkspaceSchema, false, false);
}   
export function isPartialWorkspace(o?: any): o is Partial<Workspace> {
    return isPartiallyDescribedByJsonSchema(o, WorkspaceSchema);
}   
            
import {WorkspaceEnablement, instanceOfWorkspaceEnablement, WorkspaceEnablementFromJSON} from './models'; 
                
export const WorkspaceEnablementSchema: any = {
    type: 'object',
    properties: {
        productId: {
            type: 'integer',
            format: 'int64',
        },
        productEnablement: {
            type: 'string',
            enum: [
                'ON',
                'OFF',
                'DEFAULT',
            ],
        },
    },
    required: [
        'id',
        'productEnablement',
    ],
};       

export const asWorkspaceEnablement = generateConverterToRx<WorkspaceEnablement>(WorkspaceEnablementSchema, 'WorkspaceEnablementSchema', WorkspaceEnablementFromJSON, instanceOfWorkspaceEnablement, 'toCamelCase');
export const asWorkspaceEnablementArray = generateArrayConverter<WorkspaceEnablement>(asWorkspaceEnablement);

export function isWorkspaceEnablement(o?: any): o is WorkspaceEnablement {
    return isDescribedByJsonSchema(o, WorkspaceEnablementSchema, false, false);
}   
export function isPartialWorkspaceEnablement(o?: any): o is Partial<WorkspaceEnablement> {
    return isPartiallyDescribedByJsonSchema(o, WorkspaceEnablementSchema);
}   
            
import {UpdateGroupRequest, instanceOfUpdateGroupRequest, UpdateGroupRequestFromJSON} from './models'; 
                
export const UpdateGroupRequestSchema: any = {
    type: 'object',
    properties: {
        group: {
            type: 'object',
            properties: {
                id: {
                    type: 'integer',
                    format: 'int64',
                },
                name: {
                    type: 'string',
                },
                description: {
                    type: 'string',
                },
                totalAgents: {
                    type: 'integer',
                },
                editorName: {
                    type: 'string',
                },
            },
        },
        workspace: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    productId: {
                        type: 'integer',
                        format: 'int64',
                    },
                    productEnablement: {
                        type: 'string',
                        enum: [
                            'ON',
                            'OFF',
                            'DEFAULT',
                        ],
                    },
                },
                required: [
                    'id',
                    'productEnablement',
                ],
            },
        },
        redapps: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    productId: {
                        type: 'integer',
                        format: 'int64',
                    },
                    redAppPlacement: {
                        type: 'string',
                        enum: [
                            'apps-panel',
                            'sidebar',
                        ],
                    },
                    redAppConfiguration: {
                        type: 'string',
                    },
                },
                required: [
                    'productId',
                    'redAppPlacement',
                    'redAppConfiguration',
                ],
            },
        },
        properties: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    propertyId: {
                        type: 'integer',
                        format: 'int64',
                    },
                    overrideValue: {
                        type: 'string',
                    },
                    allowUserOverride: {
                        type: 'boolean',
                    },
                },
                required: [
                    'propertyId',
                    'allowUserOverride',
                ],
            },
        },
        addedCmGroupIds: {
            type: 'array',
            items: {
                type: 'integer',
                format: 'int64',
            },
        },
        removedCmGroupIds: {
            type: 'array',
            items: {
                type: 'integer',
                format: 'int64',
            },
        },
    },
    required: [
        'group',
    ],
};       

export const asUpdateGroupRequest = generateConverterToRx<UpdateGroupRequest>(UpdateGroupRequestSchema, 'UpdateGroupRequestSchema', UpdateGroupRequestFromJSON, instanceOfUpdateGroupRequest, 'toCamelCase');
export const asUpdateGroupRequestArray = generateArrayConverter<UpdateGroupRequest>(asUpdateGroupRequest);

export function isUpdateGroupRequest(o?: any): o is UpdateGroupRequest {
    return isDescribedByJsonSchema(o, UpdateGroupRequestSchema, false, false);
}   
export function isPartialUpdateGroupRequest(o?: any): o is Partial<UpdateGroupRequest> {
    return isPartiallyDescribedByJsonSchema(o, UpdateGroupRequestSchema);
}   
            
import {RedApp, instanceOfRedApp, RedAppFromJSON} from './models'; 
                
export const RedAppSchema: any = {
    type: 'object',
    required: [
        'name',
        'iu_name',
    ],
    properties: {
        name: {
            type: 'string',
        },
        iuName: {
            type: 'string',
        },
    },
};       

export const asRedApp = generateConverterToRx<RedApp>(RedAppSchema, 'RedAppSchema', RedAppFromJSON, instanceOfRedApp, 'toCamelCase');
export const asRedAppArray = generateArrayConverter<RedApp>(asRedApp);

export function isRedApp(o?: any): o is RedApp {
    return isDescribedByJsonSchema(o, RedAppSchema, false, false);
}   
export function isPartialRedApp(o?: any): o is Partial<RedApp> {
    return isPartiallyDescribedByJsonSchema(o, RedAppSchema);
}   
            
import {RedAppResponse, instanceOfRedAppResponse, RedAppResponseFromJSON} from './models'; 
                
export const RedAppResponseSchema: any = {
    type: 'object',
    required: [
        'redApps',
        'status',
    ],
    properties: {
        redApps: {
            type: 'array',
            items: {
                type: 'object',
                required: [
                    'name',
                    'iu_name',
                ],
                properties: {
                    name: {
                        type: 'string',
                    },
                    iu_name: {
                        type: 'string',
                    },
                },
            },
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    discriminator: {
        propertyName: 'openApiType',
    },
};       

export const asRedAppResponse = generateConverterToRx<RedAppResponse>(RedAppResponseSchema, 'RedAppResponseSchema', RedAppResponseFromJSON, instanceOfRedAppResponse, 'toCamelCase');
export const asRedAppResponseArray = generateArrayConverter<RedAppResponse>(asRedAppResponse);

export function isRedAppResponse(o?: any): o is RedAppResponse {
    return isDescribedByJsonSchema(o, RedAppResponseSchema, false, false);
}   
export function isPartialRedAppResponse(o?: any): o is Partial<RedAppResponse> {
    return isPartiallyDescribedByJsonSchema(o, RedAppResponseSchema);
}   
            
import {GetRoleResponse, instanceOfGetRoleResponse, GetRoleResponseFromJSON} from './models'; 
                
export const GetRoleResponseSchema: any = {
    type: 'object',
    properties: {
        role: {
            type: 'object',
            properties: {
                parentRoles: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties: {
                            name: {
                                type: 'string',
                            },
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            enabled: {
                                type: 'boolean',
                            },
                        },
                        required: [
                            'name',
                            'id',
                            'enabled',
                        ],
                    },
                },
                id: {
                    type: 'integer',
                    format: 'int64',
                },
                name: {
                    type: 'string',
                },
            },
            required: [
                'parentRoles',
                'id',
                'name',
            ],
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    required: [
        'role',
        'status',
    ],
    discriminator: {
        propertyName: 'openApiType',
    },
};       

export const asGetRoleResponse = generateConverterToRx<GetRoleResponse>(GetRoleResponseSchema, 'GetRoleResponseSchema', GetRoleResponseFromJSON, instanceOfGetRoleResponse, 'toCamelCase');
export const asGetRoleResponseArray = generateArrayConverter<GetRoleResponse>(asGetRoleResponse);

export function isGetRoleResponse(o?: any): o is GetRoleResponse {
    return isDescribedByJsonSchema(o, GetRoleResponseSchema, false, false);
}   
export function isPartialGetRoleResponse(o?: any): o is Partial<GetRoleResponse> {
    return isPartiallyDescribedByJsonSchema(o, GetRoleResponseSchema);
}   
            
import {ParentRole, instanceOfParentRole, ParentRoleFromJSON} from './models'; 
                
export const ParentRoleSchema: any = {
    type: 'object',
    properties: {
        name: {
            type: 'string',
        },
        id: {
            type: 'integer',
            format: 'int64',
        },
        enabled: {
            type: 'boolean',
        },
    },
    required: [
        'name',
        'id',
        'enabled',
    ],
};       

export const asParentRole = generateConverterToRx<ParentRole>(ParentRoleSchema, 'ParentRoleSchema', ParentRoleFromJSON, instanceOfParentRole, 'toCamelCase');
export const asParentRoleArray = generateArrayConverter<ParentRole>(asParentRole);

export function isParentRole(o?: any): o is ParentRole {
    return isDescribedByJsonSchema(o, ParentRoleSchema, false, false);
}   
export function isPartialParentRole(o?: any): o is Partial<ParentRole> {
    return isPartiallyDescribedByJsonSchema(o, ParentRoleSchema);
}   
            
import {PartialParentRole, instanceOfPartialParentRole, PartialParentRoleFromJSON} from './models'; 
                
export const PartialParentRoleSchema: any = {
    type: 'object',
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        enabled: {
            type: 'boolean',
        },
    },
    required: [
        'id',
        'enabled',
    ],
};       

export const asPartialParentRole = generateConverterToRx<PartialParentRole>(PartialParentRoleSchema, 'PartialParentRoleSchema', PartialParentRoleFromJSON, instanceOfPartialParentRole, 'toCamelCase');
export const asPartialParentRoleArray = generateArrayConverter<PartialParentRole>(asPartialParentRole);

export function isInstanceOfPartialParentRole(o?: any): o is PartialParentRole {
    return isDescribedByJsonSchema(o, PartialParentRoleSchema, false, false);
}   
export function isPartialInstanceOfPartialParentRole(o?: any): o is Partial<PartialParentRole> {
    return isPartiallyDescribedByJsonSchema(o, PartialParentRoleSchema);
}   
            
import {PartialRole, instanceOfPartialRole, PartialRoleFromJSON} from './models'; 
                
export const PartialRoleSchema: any = {
    type: 'object',
    properties: {
        id: {
            type: 'integer',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
    },
    required: [
        'id',
        'name',
    ],
};       

export const asPartialRole = generateConverterToRx<PartialRole>(PartialRoleSchema, 'PartialRoleSchema', PartialRoleFromJSON, instanceOfPartialRole, 'toCamelCase');
export const asPartialRoleArray = generateArrayConverter<PartialRole>(asPartialRole);

export function isInstanceOfPartialRole(o?: any): o is PartialRole {
    return isDescribedByJsonSchema(o, PartialRoleSchema, false, false);
}   
export function isPartialInstanceOfPartialRole(o?: any): o is Partial<PartialRole> {
    return isPartiallyDescribedByJsonSchema(o, PartialRoleSchema);
}   
            
import {Role, instanceOfRole, RoleFromJSON} from './models'; 
                
export const RoleSchema: any = {
    type: 'object',
    properties: {
        parentRoles: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    name: {
                        type: 'string',
                    },
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    enabled: {
                        type: 'boolean',
                    },
                },
                required: [
                    'name',
                    'id',
                    'enabled',
                ],
            },
        },
        id: {
            type: 'integer',
            format: 'int64',
        },
        name: {
            type: 'string',
        },
    },
    required: [
        'parentRoles',
        'id',
        'name',
    ],
};       

export const asRole = generateConverterToRx<Role>(RoleSchema, 'RoleSchema', RoleFromJSON, instanceOfRole, 'toCamelCase');
export const asRoleArray = generateArrayConverter<Role>(asRole);

export function isRole(o?: any): o is Role {
    return isDescribedByJsonSchema(o, RoleSchema, false, false);
}   
export function isPartialRole(o?: any): o is Partial<Role> {
    return isPartiallyDescribedByJsonSchema(o, RoleSchema);
}   
            
import {RoleRequest, instanceOfRoleRequest, RoleRequestFromJSON} from './models'; 
                
export const RoleRequestSchema: any = {
    type: 'object',
    properties: {
        name: {
            type: 'string',
        },
    },
    required: [
        'name',
    ],
};       

export const asRoleRequest = generateConverterToRx<RoleRequest>(RoleRequestSchema, 'RoleRequestSchema', RoleRequestFromJSON, instanceOfRoleRequest, 'toCamelCase');
export const asRoleRequestArray = generateArrayConverter<RoleRequest>(asRoleRequest);

export function isRoleRequest(o?: any): o is RoleRequest {
    return isDescribedByJsonSchema(o, RoleRequestSchema, false, false);
}   
export function isPartialRoleRequest(o?: any): o is Partial<RoleRequest> {
    return isPartiallyDescribedByJsonSchema(o, RoleRequestSchema);
}   
            
import {RoleResponse, instanceOfRoleResponse, RoleResponseFromJSON} from './models'; 
                
export const RoleResponseSchema: any = {
    type: 'object',
    properties: {
        role: {
            type: 'object',
            properties: {
                parentRoles: {
                    type: 'array',
                    items: {
                        type: 'object',
                        properties: {
                            name: {
                                type: 'string',
                            },
                            id: {
                                type: 'integer',
                                format: 'int64',
                            },
                            enabled: {
                                type: 'boolean',
                            },
                        },
                        required: [
                            'name',
                            'id',
                            'enabled',
                        ],
                    },
                },
                id: {
                    type: 'integer',
                    format: 'int64',
                },
                name: {
                    type: 'string',
                },
            },
            required: [
                'parentRoles',
                'id',
                'name',
            ],
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    required: [
        'role',
        'status',
    ],
    discriminator: {
        propertyName: 'openApiType',
    },
};       

export const asRoleResponse = generateConverterToRx<RoleResponse>(RoleResponseSchema, 'RoleResponseSchema', RoleResponseFromJSON, instanceOfRoleResponse, 'toCamelCase');
export const asRoleResponseArray = generateArrayConverter<RoleResponse>(asRoleResponse);

export function isRoleResponse(o?: any): o is RoleResponse {
    return isDescribedByJsonSchema(o, RoleResponseSchema, false, false);
}   
export function isPartialRoleResponse(o?: any): o is Partial<RoleResponse> {
    return isPartiallyDescribedByJsonSchema(o, RoleResponseSchema);
}   
            
import {SearchRoleRequest, instanceOfSearchRoleRequest, SearchRoleRequestFromJSON} from './models'; 
                
export const SearchRoleRequestSchema: any = {
    type: 'object',
    properties: {
        pattern: {
            type: 'string',
        },
    },
    required: [
        'pattern',
    ],
};       

export const asSearchRoleRequest = generateConverterToRx<SearchRoleRequest>(SearchRoleRequestSchema, 'SearchRoleRequestSchema', SearchRoleRequestFromJSON, instanceOfSearchRoleRequest, 'toCamelCase');
export const asSearchRoleRequestArray = generateArrayConverter<SearchRoleRequest>(asSearchRoleRequest);

export function isSearchRoleRequest(o?: any): o is SearchRoleRequest {
    return isDescribedByJsonSchema(o, SearchRoleRequestSchema, false, false);
}   
export function isPartialSearchRoleRequest(o?: any): o is Partial<SearchRoleRequest> {
    return isPartiallyDescribedByJsonSchema(o, SearchRoleRequestSchema);
}   
            
import {SearchRoleResponse, instanceOfSearchRoleResponse, SearchRoleResponseFromJSON} from './models'; 
                
export const SearchRoleResponseSchema: any = {
    type: 'object',
    properties: {
        roles: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    name: {
                        type: 'string',
                    },
                },
                required: [
                    'id',
                    'name',
                ],
            },
        },
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
    required: [
        'roles',
        'status',
    ],
    discriminator: {
        propertyName: 'openApiType',
    },
};       

export const asSearchRoleResponse = generateConverterToRx<SearchRoleResponse>(SearchRoleResponseSchema, 'SearchRoleResponseSchema', SearchRoleResponseFromJSON, instanceOfSearchRoleResponse, 'toCamelCase');
export const asSearchRoleResponseArray = generateArrayConverter<SearchRoleResponse>(asSearchRoleResponse);

export function isSearchRoleResponse(o?: any): o is SearchRoleResponse {
    return isDescribedByJsonSchema(o, SearchRoleResponseSchema, false, false);
}   
export function isPartialSearchRoleResponse(o?: any): o is Partial<SearchRoleResponse> {
    return isPartiallyDescribedByJsonSchema(o, SearchRoleResponseSchema);
}   
            
import {UpdateRoleRequest, instanceOfUpdateRoleRequest, UpdateRoleRequestFromJSON} from './models'; 
                
export const UpdateRoleRequestSchema: any = {
    type: 'object',
    properties: {
        parentRoles: {
            type: 'array',
            items: {
                type: 'object',
                properties: {
                    id: {
                        type: 'integer',
                        format: 'int64',
                    },
                    enabled: {
                        type: 'boolean',
                    },
                },
                required: [
                    'id',
                    'enabled',
                ],
            },
        },
    },
    required: [
        'parentRoles',
    ],
};       

export const asUpdateRoleRequest = generateConverterToRx<UpdateRoleRequest>(UpdateRoleRequestSchema, 'UpdateRoleRequestSchema', UpdateRoleRequestFromJSON, instanceOfUpdateRoleRequest, 'toCamelCase');
export const asUpdateRoleRequestArray = generateArrayConverter<UpdateRoleRequest>(asUpdateRoleRequest);

export function isUpdateRoleRequest(o?: any): o is UpdateRoleRequest {
    return isDescribedByJsonSchema(o, UpdateRoleRequestSchema, false, false);
}   
export function isPartialUpdateRoleRequest(o?: any): o is Partial<UpdateRoleRequest> {
    return isPartiallyDescribedByJsonSchema(o, UpdateRoleRequestSchema);
}   
            
import {Status, instanceOfStatus, StatusFromJSON} from './models'; 
                
export const StatusSchema: any = {
    type: 'object',
    discriminator: {
        propertyName: 'openApiType',
    },
    required: [
        'status',
    ],
    properties: {
        status: {
            type: 'string',
            enum: [
                'SUCCESS',
                'ERROR',
            ],
        },
        statusDescription: {
            type: 'string',
        },
    },
};       

export const asStatus = generateConverterToRx<Status>(StatusSchema, 'StatusSchema', StatusFromJSON, instanceOfStatus, 'toCamelCase');
export const asStatusArray = generateArrayConverter<Status>(asStatus);

export function isStatus(o?: any): o is Status {
    return isDescribedByJsonSchema(o, StatusSchema, false, false);
}   
export function isPartialStatus(o?: any): o is Partial<Status> {
    return isPartiallyDescribedByJsonSchema(o, StatusSchema);
}   
            
import {Version, instanceOfVersion, VersionFromJSON} from './models'; 
                
export const VersionSchema: any = {
    type: 'object',
    required: [
        'timestamp',
        'branch',
        'checksum',
        'version',
    ],
    properties: {
        timestamp: {
            type: 'string',
        },
        branch: {
            type: 'string',
        },
        checksum: {
            type: 'string',
        },
        version: {
            type: 'string',
        },
    },
};       

export const asVersion = generateConverterToRx<Version>(VersionSchema, 'VersionSchema', VersionFromJSON, instanceOfVersion, 'toCamelCase');
export const asVersionArray = generateArrayConverter<Version>(asVersion);

export function isVersion(o?: any): o is Version {
    return isDescribedByJsonSchema(o, VersionSchema, false, false);
}   
export function isPartialVersion(o?: any): o is Partial<Version> {
    return isPartiallyDescribedByJsonSchema(o, VersionSchema);
}   
            
